const Analytics = {
	track: (eventName, params) => {
		if (window.mixpanel) {
			window.mixpanel.track(eventName, params)
		}
	}
}

const EventTypes = {
	LOAD: 'LOAD',
	RENDER_SCREENSHOTS: 'RENDER_SCREENSHOTS',
	// device
	ADD_DEVICE: 'ADD_DEVICE',
	TOGGLE_DEVICE: 'TOGGLE_DEVICE',
	REMOVE_DEVICE: 'REMOVE_DEVICE',
	CHANGE_DEVICE_TYPE_TAB: 'CHANGE_DEVICE_TYPE_TAB',
	// options
	ADD_OPTION: 'ADD_OPTION',
}

module.exports = {
	Analytics,
	EventTypes
} 