import React, { Component } from 'react';
import {
	Navbar,
	NavbarDivider,
	NavbarGroup,
	NavbarHeading,
	Button,
	Alignment,
	Classes,
	Card,
	InputGroup,
	Intent,
	Switch,
	Dialog,
	FormGroup,
	Spinner,
	Callout,
	Tabs,
	Tab
} from '@blueprintjs/core'
import axios from 'axios'
import Gallery from 'react-grid-gallery'
import queryString from 'query-string'
import { v4 as uuidv4 } from 'uuid'

import './styles.css'
import logo from './logo.svg'
import { Analytics, EventTypes } from '../../helpers/analytics'

import { Position, Toaster } from "@blueprintjs/core";

/** Singleton toaster instance. Create separate instances for different options. */
const AppToaster = Toaster.create({
	position: Position.TOP,
});

let API_URL = 'https://hm0a913hf6.execute-api.us-east-1.amazonaws.com/dev/capture'
if (process.env.NODE_ENV === 'production') {
	API_URL = 'https://cp82i7sfi4.execute-api.us-east-1.amazonaws.com/prod/capture'
}
const DEFAULT_DEVICES = require('../../config/devices')

const DevicesTypes = {
	DESKTOP: 'desktop',
	MOBILE: 'mobile',
	TABLET: 'tablet'
}

class Home extends Component {
	constructor() {
		super()
		let custom_devices = []

		if (window.localStorage && localStorage.getItem('devices')) {
			try {
				custom_devices = JSON.parse(localStorage.getItem('devices'))
			} catch (e) { }
		}

		Analytics.track(EventTypes.LOAD, {
			custom_devices_count: custom_devices.length
		})

		this.state = {
			websiteUrl: 'https://www.apple.com',
			loading: false,
			options: {
				delay: false
			},
			renders: [],
			showAddDeviceDialog: false,
			newDevice: {},
			devices: DEFAULT_DEVICES.map(device => ({
				...device,
				id: uuidv4(),
			})),
			custom_devices,
			devicesActiveTab: DevicesTypes.DESKTOP
		}
	}

	componentDidMount() {
		if (this.props && this.props.location && this.props.location.search) {
			const queryParams = queryString.parse(this.props.location.search)
			if (queryParams.url) {
				this.setState({
					websiteUrl: queryParams.url
				}, () => {
					this.submitForm()
				})
				return
			}
		}
	}

	submitForm = () => {
		if (window.localStorage) {
			try {
				localStorage.setItem('devices', JSON.stringify(this.state.custom_devices))
			} catch (e) {
				console.warn('localStorage', e)
			}
		}

		this.setState({
			renders: [],
			loading: true,
			error: false
		})

		const all_devices = [...this.state.devices, ...this.state.custom_devices].filter(device => device.enabled)

		if (all_devices.length > 10) {
			this.setState({
				error: true
			})
			return AppToaster.show({
				message: 'A maximum of 10 devices are allowed in a request',
				intent: Intent.DANGER
			})
		}

		Analytics.track(EventTypes.RENDER_SCREENSHOTS, {
			url: this.state.websiteUrl,
			options: this.state.options,
			all_devices_count: all_devices.length,
			custom_devices_count: this.state.custom_devices.length
		})

		axios.post(API_URL, {
			url: this.state.websiteUrl,
			devices: all_devices,
			options: this.state.options
		}).then(response => {
			const renders = response.data.renders.map(render => ({
				src: render.image_url,
				thumbnail: render.thumbnail_url,
				thumbnailWidth: render.thumbnailWidth,
				thumbnailHeight: render.thumbnailHeight,
				caption: render.caption || '',
				tags: render.caption.split(',').map(item => ({
					value: item,
					title: item
				}))
			}))
			this.setState({
				renders,
				loading: false
			})
		}).catch(error => {
			this.setState({
				renders: [],
				loading: false
			})
			AppToaster.show({ message: error.message })
		})
	}

	handleUrlChange = (event) => {
		this.setState({
			websiteUrl: event.target.value || ''
		})
	}

	renderNavigation = () => <Navbar>
		<NavbarGroup align={Alignment.LEFT}>
			<NavbarHeading>
				<img src={logo} alt="Screen Dump" width={280} />
			</NavbarHeading>
		</NavbarGroup>
		<NavbarGroup align={Alignment.RIGHT} className="desktop-only">
			<Button className={Classes.MINIMAL} icon="build" text="API" onClick={() => window.open('https://capture.techulus.in')} />
			<NavbarDivider />
			<Button className={Classes.MINIMAL} icon="envelope" text="Feedback" onClick={() => window.location.href = 'mailto:screendump@techulus.com'} />
			<Button className={Classes.MINIMAL} icon="lightbulb" text="Feature Requests" onClick={() => window.location.href = 'mailto:screendump@techulus.com'} />
		</NavbarGroup>
	</Navbar>

	renderDevice = (device, i) => <div key={i} className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
		<Switch large checked={device.enabled} label={device.name} onChange={() => this.toggleDevice(device.id)} />
	</div>

	renderCustomDevice = (device, i) => <div key={i} className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
		<Switch large checked={device.enabled} label={device.name} onChange={() => this.toggleCustomDevice(i)}>
			<Button className="delete-btn" small onClick={() => this.deleteDevice(i)} icon="trash" />
		</Switch>
	</div>

	deleteDevice = (i) => {
		Analytics.track(EventTypes.REMOVE_DEVICE)
		const newDevices = [...this.state.custom_devices].filter((d, index) => index !== i)
		this.setState({
			custom_devices: newDevices
		}, () => {
			// this.submitForm()
		})
	}

	toggleDevice = (id) => {
		Analytics.track(EventTypes.TOGGLE_DEVICE)
		const newDevices = [...this.state.devices]
		this.setState({
			devices: newDevices.map(device => {
				if (device.id === id) {
					return {
						...device,
						enabled: !device.enabled
					}
				}
				return device
			})
		}, () => {
			// this.submitForm()
		})
	}

	toggleCustomDevice = (i) => {
		Analytics.track(EventTypes.TOGGLE_DEVICE)
		const newDevices = [...this.state.custom_devices]
		newDevices[i].enabled = !newDevices[i].enabled
		this.setState({
			custom_devices: newDevices
		})
		// this.submitForm()
	}

	closeAddDeviceDialog = () => {
		this.setState({
			showAddDeviceDialog: false
		})
	}

	showAddDeviceDialog = () => {
		this.setState({
			showAddDeviceDialog: true
		})
	}

	handleCustomDeviceInput = (field, value) => {
		switch (field) {
			case 'vh':
			case 'vw': if (isNaN(value) || value > 10000) value = 1000; break;
			default: break;
		}
		this.setState({
			newDevice: {
				...this.state.newDevice,
				[field]: value
			}
		})
	}

	addDevice = () => {
		Analytics.track(EventTypes.ADD_DEVICE, {
			device: this.state.newDevice
		})
		this.setState({
			custom_devices: [...this.state.custom_devices, {
				...this.state.newDevice,
				enabled: true
			}],
			newDevice: {},
			showAddDeviceDialog: false
		}, () => {
			// this.submitForm()
		})
	}

	renderAddDeviceDialog = () => <Dialog
		icon="info-sign"
		onClose={this.closeAddDeviceDialog}
		title="Add Custom Device"
		isOpen={this.state.showAddDeviceDialog}
	>
		<div className={Classes.DIALOG_BODY}>
			<FormGroup
				label="Device Name"
				labelFor="name-input"
				labelInfo="(required)"
			>
				<InputGroup id="name-input" type="text" placeholder="Enter Device Name" onChange={(event) => this.handleCustomDeviceInput('name', event.target.value)} />
			</FormGroup>
			<FormGroup
				label="Screen Width"
				labelFor="width-input"
				labelInfo="(required)"
			>
				<InputGroup id="width-input" type="number" placeholder="Enter Screen Width" onChange={(event) => this.handleCustomDeviceInput('vw', event.target.value)} />
			</FormGroup>
			<FormGroup
				label="Screen Height"
				labelFor="height-input"
				labelInfo="(required)"
			>
				<InputGroup id="height-input" type="number" placeholder="Enter Screen Height" onChange={(event) => this.handleCustomDeviceInput('vh', event.target.value)} />
			</FormGroup>
			<FormGroup
				label="User Agent"
				labelFor="user-agent"
				labelInfo="(optional)"
			>
				<InputGroup id="user-agent" type="text" placeholder="Enter User Agent" onChange={(event) => this.handleCustomDeviceInput('userAgent', event.target.value)} />
			</FormGroup>
		</div>
		<div className={Classes.DIALOG_FOOTER}>
			<div className={Classes.DIALOG_FOOTER_ACTIONS}>
				<Button intent={Intent.SUCCESS} onClick={this.addDevice}>Add Device</Button>
				<Button onClick={this.closeAddDeviceDialog}>Close</Button>
			</div>
		</div>
	</Dialog>

	handleTabChange = (tab) => {
		Analytics.track(EventTypes.CHANGE_DEVICE_TYPE_TAB)
		this.setState({
			devicesActiveTab: tab
		})
	}

	updateOptions = (key, value) => {
		Analytics.track(EventTypes.ADD_OPTION, {
			[key]: value
		})
		this.setState({
			options: {
				...this.state.options,
				[key]: this.state.options[key] ? false : value
			}
		})
	}

	render() {
		const {
			devices,
			loading,
			error
		} = this.state
		return (
			<div>
				{this.renderAddDeviceDialog()}
				<div className="container">
					{this.renderNavigation()}
					<Card>
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
								<h2>Enter your website URL</h2>
								<InputGroup id="text-input" placeholder="Placeholder text" value={this.state.websiteUrl} onChange={this.handleUrlChange} />
								<Switch large style={{ marginTop: 10 }} label={'Add 5 seconds delay before capturing screenshot'} onChange={() => this.updateOptions('delay', 5)} />
								<Switch large style={{ marginTop: 5 }} label={'Capture full page screenshot'} onChange={() => this.updateOptions('fullPage', true)} />
								<Button large className="render-btn" style={{ marginBottom: 15 }} intent={Intent.PRIMARY} onClick={this.submitForm}>Submit</Button>
							</div>
							<div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
								<h2 style={{ marginBottom: 0 }}>Devices ({devices.length})</h2>

								<Tabs id="devices-tabs" large renderActiveTabPanelOnly onChange={this.handleTabChange} selectedTabId={this.state.devicesActiveTab}>
									<Tab id={DevicesTypes.DESKTOP} title={`Desktop (${devices.filter(device => device.type === DevicesTypes.DESKTOP).length})`} panel={<div className="row">
										{devices.filter(device => device.type === DevicesTypes.DESKTOP).map(this.renderDevice)}
									</div>} />
									<Tab id={DevicesTypes.MOBILE} title={`Mobile (${devices.filter(device => device.type === DevicesTypes.MOBILE).length})`} panel={<div className="row">
										{devices.filter(device => device.type === DevicesTypes.MOBILE).map(this.renderDevice)}
									</div>} />
									<Tab id={DevicesTypes.TABLET} title={`iPads (${devices.filter(device => device.type === DevicesTypes.TABLET).length})`} panel={<div className="row">
										{devices.filter(device => device.type === DevicesTypes.TABLET).map(this.renderDevice)}
									</div>} />
									<Tabs.Expander />
								</Tabs>

								{!!this.state.custom_devices.length && <React.Fragment>
									<h2 className="custom-devices-head">Custom Devices</h2>
									<div className="row">
										{this.state.custom_devices.map(this.renderCustomDevice)}
									</div>
								</React.Fragment>}
								<Button className="render-btn" intent={Intent.PRIMARY} onClick={this.showAddDeviceDialog}>Add Custom Device</Button>
							</div>
						</div>
					</Card>

					<a className={'powered-by'} href={'https://capture.techulus.in'} target={'_blank'} rel="noopener noreferrer">
						<h2 style={{ margin: 0 }}>Powered by </h2>
						<img className={'capture-logo'} alt={'Capture'} width={35} src={'https://capture.techulus.in/assets/paper_img/logo.png'} />
						<h1 className={'capture'}>Capture</h1>
					</a>

					<h1 className='head'>
						Screenshots
					</h1>
					{!!this.state.renders.length && <p className='subtitle'>Click on the thumbnail to view screenshot</p>}

					{loading && <Spinner intent={Intent.PRIMARY} />}

					{!loading && !this.state.renders.length && <Callout intent={Intent.PRIMARY} title={'Ready!'}>
						<p style={{ margin: 0 }}>Enter your URL and click submit to begin.</p>
					</Callout>}

					{error && <Callout intent={Intent.DANGER} title={'Oops!'}>
						<p style={{ margin: 0 }}>A maximum of 10 devices are allowed in a request</p>
					</Callout>}

					{!error && <Gallery
						images={this.state.renders}
						enableImageSelection={false}
						rowHeight={400}
						margin={35}
						tagStyle={{
							padding: 5,
							fontSize: 12,
							fontWeight: 'bold',
							background: '#5C7080',
							color: 'white'
						}} />}
				</div>
			</div>
		);
	}
}

export default Home;
