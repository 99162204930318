const DevicesTypes = {
	DESKTOP: 'desktop',
	MOBILE: 'mobile',
	TABLET: 'tablet'
}

module.exports = [
	{
		name: 'Desktop 1024x768',
		vw: 1024,
		vh: 768,
		type: DevicesTypes.DESKTOP,
		enabled: false
	},
	{
		name: 'Desktop 1280x800',
		vw: 1280,
		vh: 800,
		type: DevicesTypes.DESKTOP,
		enabled: true
	},
	{
		name: 'Desktop 1366x768',
		vw: 1366,
		vh: 768,
		type: DevicesTypes.DESKTOP,
		enabled: false
	},
	{
		name: 'Desktop 1440x900',
		vw: 1440,
		vh: 900,
		type: DevicesTypes.DESKTOP,
		enabled: false
	},
	{
		name: 'Desktop 1536x864',
		vw: 1536,
		vh: 864,
		type: DevicesTypes.DESKTOP,
		enabled: false
	},
	{
		name: 'Desktop 1600x900',
		vw: 1600,
		vh: 900,
		type: DevicesTypes.DESKTOP,
		enabled: false
	},
	{
		name: 'Desktop FHD',
		vw: 1920,
		vh: 1080,
		type: DevicesTypes.DESKTOP,
		enabled: false
	},
	{
		name: 'Desktop QHD',
		vw: 2560,
		vh: 1440,
		type: DevicesTypes.DESKTOP,
		enabled: false
	},
	{
		name: 'Desktop 4K',
		vw: 3840,
		vh: 2160,
		type: DevicesTypes.DESKTOP,
		enabled: false
	},
	{
		name: '21.5-inch iMac (4K)',
		vw: 4096,
		vh: 2304,
		type: DevicesTypes.DESKTOP,
		enabled: false
	},
	{
		name: '27-inch iMac (5K)',
		vw: 5120,
		vh: 2880,
		type: DevicesTypes.DESKTOP,
		enabled: false
	},
	{
		name: 'iPhone 5/SE',
		vw: 320,
		vh: 568,
		type: DevicesTypes.MOBILE,
		userAgent: 'Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_1 like Mac OS X) AppleWebKit/603.1.30 (KHTML, like Gecko) Version/10.0 Mobile/14E304 Safari/602.1',
		enabled: false
	},
	{
		name: 'iPhone 6/7/8',
		vw: 375,
		vh: 667,
		type: DevicesTypes.MOBILE,
		userAgent: 'Mozilla/5.0 (iPhone; CPU iPhone OS 11_0 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A372 Safari/604.1',
		enabled: false
	},
	{
		name: 'iPhone 6/7/8 Plus',
		vw: 414,
		vh: 736,
		type: DevicesTypes.MOBILE,
		userAgent: 'Mozilla/5.0 (iPhone; CPU iPhone OS 11_0 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A372 Safari/604.1',
		enabled: false
	},
	{
		name: 'iPhone 11 Pro/XS/X',
		vw: 375,
		vh: 812,
		type: DevicesTypes.MOBILE,
		userAgent: 'Mozilla/5.0 (iPhone; CPU iPhone OS 11_0 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A372 Safari/604.1',
		enabled: true
	},
	{
		name: 'iPhone 11/XR',
		vw: 414,
		vh: 896,
		type: DevicesTypes.MOBILE,
		userAgent: 'Mozilla/5.0 (iPhone; CPU iPhone OS 11_0 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A372 Safari/604.1',
		enabled: false
	},
	{
		name: 'iPhone 11 Pro/XS Max',
		vw: 414,
		vh: 896,
		type: DevicesTypes.MOBILE,
		userAgent: 'Mozilla/5.0 (iPhone; CPU iPhone OS 11_0 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A372 Safari/604.1',
		enabled: false
	},
	{
		name: 'iPad',
		vw: 768,
		vh: 1024,
		type: DevicesTypes.TABLET,
		userAgent: 'Mozilla/5.0 (iPad; CPU OS 11_0 like Mac OS X) AppleWebKit/604.1.34 (KHTML, like Gecko) Version/11.0 Mobile/15A5341f Safari/604.1',
		enabled: false
	},
	{
		name: 'iPad Pro',
		vw: 1024,
		vh: 1366,
		type: DevicesTypes.TABLET,
		userAgent: 'Mozilla/5.0 (iPad; CPU OS 11_0 like Mac OS X) AppleWebKit/604.1.34 (KHTML, like Gecko) Version/11.0 Mobile/15A5341f Safari/604.1',
		enabled: true
	},
	{
		name: 'Pixel 2',
		vw: 411,
		vh: 731,
		type: DevicesTypes.MOBILE,
		userAgent: 'Mozilla/5.0 (Linux; Android 8.0; Pixel 2 Build/OPD3.170816.012) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/75.0.3753.4 Mobile Safari/537.36',
		enabled: false
	},
	{
		name: 'Pixel 2 XL',
		vw: 411,
		vh: 823,
		type: DevicesTypes.MOBILE,
		userAgent: 'Mozilla/5.0 (Linux; Android 8.0.0; Pixel 2 XL Build/OPD1.170816.004) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/75.0.3753.4 Mobile Safari/537.36',
		enabled: false
	}
];